import _get from 'lodash/get'

export const apiResponseErrorHandler = ({ error, dispatch, fallback = null }) => {
  const status = _get(error, 'response.status')
  const message = _get(error, 'response.data.msg', 'Something went wrong!')
  if (fallback && typeof fallback === 'function') {
    fallback(message)
    return
  }
  if (!dispatch) return

  let payload = message
  if (payload === 'Invalid input: {"passcode":["The passcode field is required."]}')
    payload = 'Enter a passcode to join an Outing'
  else if (status === 419) {
    payload = {
      additional: {
        message: `The session has expired. Please close the app by clicking the three dots (...) above and selecting "Close" and launch the app again`,
        btnClose: 'Continue',
      },
    }
  }

  dispatch({
    type: 'SET_ALERT_MESSAGE',
    payload,
  })
  dispatch({ type: 'SHOW_MODAL_MESSAGE', payload: true })
}
