import Head from 'next/head'
import { createRef, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import Cookies from 'js-cookie'
import Image from 'next/image'
import { useAppContext } from 'contexts/app_context'
import { useViewport } from 'contexts/viewport'
import { apiResponseErrorHandler } from 'utils/apiHandler'
import { useQuery } from 'react-query'
import { actions as globalActions } from 'redux/global_slice'
import Slider from 'react-slick'
import * as gtag from 'lib/gtag'

import ModalMessage from 'components/modals/modal_message'
import ModalJoinOuting from 'components/modals/modal_join_outing'
import AppHeader from 'components/app_header'
import AppFooter from 'components/app_footer'

import styles from 'styles/home.module.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import IconArrowButton from 'components/icon/arrow_button'
import {
  AppTitle,
  GameSlug,
  TeamGamesMetaDescription,
  TeamGamesOGImagePath,
  GameScreenshotAltText,
} from 'utils/constants'

const getGames = async () => {
  var jwtToken = Cookies.get('jwtToken')
  if (!jwtToken) {
    const resToken = await fetch(`${process.env.API_HOST}/api/guest/token`).then(res => res.json())
    jwtToken = resToken.jwt
  }
  const resGame = await fetch(`${process.env.API_HOST}/outings/get-list-game`, {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  })
  return resGame.json()
}

const IndexPage = () => {
  const ref = createRef()
  const [state, dispatch] = useAppContext()
  const { isMobile } = useViewport()
  const reduxDispatch = useDispatch()
  const { isLoading, error, data } = useQuery('games', getGames)
  const userDisplayName = Cookies.get('displayName')
  const [displayName, setDisplayName] = useState(userDisplayName)
  const [passcode, setPasscode] = useState('')
  const [activeGameIndex, setActiveGameIndex] = useState(0)
  const [touchStart, setTouchStart] = useState(0)
  const [touchEnd, setTouchEnd] = useState(0)

  const gameSlickSettings = {
    speed: 300,
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    variableWidth: true,
    centerMode: true,
    swipe: false,
    draggable: false,
    initialSlide: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: current => {
      setActiveGameIndex(current)
    },
    responsive: [
      {
        breakpoint: 500,
        settings: {
          swipe: false,
          draggable: false,
        },
      },
    ],
  }

  useEffect(() => {
    gtag.event('Open Home Page', '')
  }, [])

  useEffect(() => {
    if (!error && !isLoading) {
      var listGame = data.data
      dispatch({
        type: 'SET_GAME_DATA',
        payload: listGame,
      })
      reduxDispatch(globalActions.setGames(listGame))
    }
  }, [dispatch, reduxDispatch, data, error, isLoading])

  const handleChangeDisplayName = event => {
    const { value } = event.target
    setDisplayName(value)
  }

  const createOuting = () => {
    var inSixtyMinutes = new Date(new Date().getTime() + 60 * 60 * 1000)
    if (!displayName?.trim()) {
      dispatch({
        type: 'SET_ALERT_MESSAGE',
        payload: 'Please enter your name before starting an outing!',
      })
      dispatch({ type: 'SHOW_MODAL_MESSAGE', payload: true })
      return
    }
    Cookies.set('displayName', displayName?.trim(), {
      expires: inSixtyMinutes,
    })
    window.location.href = '/landing'
  }

  const handleKeyDownCreateOuting = event => {
    if (event.key === 'Enter') {
      createOuting()
    }
  }

  const handleChangePasscode = event => {
    const { value } = event.target
    setPasscode(value)
  }

  const handleKeyDownJoinByPasscode = event => {
    if (event.key === 'Enter') {
      submitPasscode(event)
    }
  }

  const showModalJoinOuting = () => {
    dispatch({
      type: 'SHOW_MODAL_JOIN_OUTING',
      payload: true,
    })
  }

  const submitPasscode = async e => {
    e.preventDefault()
    try {
      const jwtToken = Cookies.get('jwtToken')
      const response = await axios.post(
        `${process.env.API_HOST}/outings/passcode-event-id`,
        {
          passcode,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        },
      )

      if (response.data.status === 1) {
        if (!displayName) {
          dispatch({
            type: 'SHOW_MODAL_JOIN_OUTING',
            payload: true,
          })
          dispatch({
            type: 'SET_MODAL_JOIN_OUTING_PASSCODE',
            payload: passcode,
          })
        } else {
          window.location.href = `/events/${passcode}`
        }
      }
    } catch (error) {
      apiResponseErrorHandler({ error, dispatch })
    }
  }

  const changeSlider = index => {
    if (index === activeGameIndex) {
      return true
    }
    if (index === activeGameIndex - 1 || (activeGameIndex === 0 && index === 5)) {
      previousGameCarousel()
    }
    if (index === activeGameIndex + 1 || (activeGameIndex === 5 && index === 0)) {
      nextGameCarousel()
    }
  }

  const handleTouchStart = e => {
    setTouchStart(e.targetTouches[0].clientX)
  }

  const handleTouchMove = e => {
    setTouchEnd(e.targetTouches[0].clientX)
  }

  const handleMouseDown = e => {
    setTouchStart(e.clientX)
  }

  const handleMouseMove = e => {
    setTouchEnd(e.clientX)
  }

  const handleSwipeEnd = () => {
    if (touchStart - touchEnd > 50) {
      nextGameCarousel()
    }

    if (touchStart - touchEnd < -50) {
      previousGameCarousel()
    }
  }

  const selectGame = index => {
    // @ts-ignore
    ref.current.slickGoTo(index)
  }

  const previousGameCarousel = () => {
    // @ts-ignore
    ref.current.slickPrev()
  }

  const nextGameCarousel = () => {
    // @ts-ignore
    ref.current.slickNext()
  }

  if (isLoading)
    return (
      <div className={styles.loading_container}>
        <Head>
          <title>{AppTitle}</title>
          <link rel="icon" href="/images/modal_logo.png" />
          <meta name="description" content={TeamGamesMetaDescription} />
          <meta name="og:image" content={TeamGamesOGImagePath} />
        </Head>
        <Image src="/images/spinner.gif" alt="Spinner" width={218} height={149} />
      </div>
    )

  if (error) return <h3>An error has occurred</h3>

  const getListPopularGame = () => {
    var listPopularGameSlug = [
      GameSlug.Pictionary,
      GameSlug.Poker,
      GameSlug.MiniGolf,
      GameSlug.EightBall,
      GameSlug.ClashOfTrivia,
      GameSlug.FourInARow,
    ]
    const listPopularGame = []
    listPopularGameSlug.forEach(gameSlug => {
      Object.keys(data.data).forEach(key => {
        if (data.data[key]['game_slug'] === gameSlug) {
          listPopularGame.push(data.data[key])
        }
      })
    })
    return listPopularGame
  }

  const listPopularGame = getListPopularGame()

  return (
    <div className={styles.container} id="home_page">
      <Head>
        <title>{AppTitle}</title>
        <link rel="icon" href="/images/modal_logo.png" />
        <meta name="description" content={TeamGamesMetaDescription} />
        <meta name="og:image" content={TeamGamesOGImagePath} />
      </Head>
      <div className={styles.header_section}>
        <AppHeader transparent />
        <div className={styles.header_bg}>
          {isMobile ? (
            <div className={styles.header_bg_img}>
              <Image src="/images/home/BackgroundMobile.png" alt="Game for Every Team" layout="fill" />
            </div>
          ) : (
            <div className={styles.header_bg_img}>
              <Image src="/images/home/Background.png" alt="Game for Every Team" layout="fill" />
            </div>
          )}
        </div>
        <div className={styles.header_container}>
          <div className={styles.header_left}>
            <div className={'hero ' + styles.header_get_started}>Connect with Your Team Members!</div>
            <div className={'description_large ' + styles.header_get_started_desc}>
              Every team needs a break! Grab your group for quick and fun team Outing with games that anyone can play,
              no other apps required!
            </div>
            <div className={'description_large ' + styles.header_label}>Enter Your Name to Begin</div>
            <div className={styles.header_input_group}>
              <input
                className={styles.header_input}
                type="text"
                placeholder="Enter Your Name"
                value={displayName}
                onChange={handleChangeDisplayName}
                onKeyDown={handleKeyDownCreateOuting}
              />
              <div className={'large_btn ' + styles.header_input_btn} onClick={() => createOuting()}>
                Create Now
                <IconArrowButton></IconArrowButton>
              </div>
            </div>
            <div className={'description_large ' + styles.header_label}>Join Your Team Now</div>
            <div className={styles.header_input_group}>
              <input
                className={styles.header_input}
                type="text"
                placeholder="Enter Outing Code"
                onChange={handleChangePasscode}
                onKeyDown={handleKeyDownJoinByPasscode}
              />
              <div className={'large_btn ' + styles.header_input_btn} onClick={() => submitPasscode(event)}>
                Join Now
                <IconArrowButton></IconArrowButton>
              </div>
            </div>
          </div>
          <div className={styles.header_right}>
            <div className={styles.item3}>
              <Image src="/images/home/Clouds_1.png" alt="Cloud Item" width="240" height="175" />
            </div>
            <div className={styles.item4}>
              <Image src="/images/home/Clouds_2.png" alt="Cloud Item" width="100" height="171" />
            </div>
            <div className={styles.big_item}></div>
          </div>
        </div>
      </div>

      <div className={styles.games_for_team}>
        <div className={styles.games_for_team_left}>
          <div className={'heading_1 ' + styles.games_for_team_left_title}>Games for Every Team</div>
          <div className={'description_large ' + styles.games_for_team_left_title_desc}>
            Whether you’re in the office or working online, we have team building games for you! From truly competitive
            to silly fun, group up with anyone in your team to conquer these engaging games.
          </div>
          {!isMobile && (
            <>
              <div className={styles.games_for_team_popular_games}>
                {listPopularGame.map((game, index) => (
                  <div
                    key={index}
                    className={
                      'heading_2 ' +
                      styles.games_for_team_popular_game +
                      (listPopularGame[activeGameIndex].game_slug === game.game_slug ? ' ' + styles.selected : '')
                    }
                    onClick={() => selectGame(index)}
                  >
                    {game.display_name}
                  </div>
                ))}
              </div>
              <div
                className={'button_big ' + styles.games_for_team_view_all}
                onClick={() => window.open('/blog/all-games')}
              >
                View All Games
              </div>
            </>
          )}
        </div>
        <div className={styles.games_for_team_right}>
          <div
            className={styles.game_screenshot_sliders}
            id="game_carousel"
            onTouchStart={touchStartEvent => handleTouchStart(touchStartEvent)}
            onTouchMove={touchMoveEvent => handleTouchMove(touchMoveEvent)}
            onTouchEnd={() => handleSwipeEnd()}
            onMouseDown={mouseDownEvent => handleMouseDown(mouseDownEvent)}
            onMouseMove={mouseMoveEvent => handleMouseMove(mouseMoveEvent)}
            onMouseUp={() => handleSwipeEnd()}
          >
            <Slider {...gameSlickSettings} ref={ref}>
              {listPopularGame.map((game, index) => (
                <div key={index} className={styles.game_screenshot_slider} onClick={() => changeSlider(index)}>
                  <Image
                    src={game['screenshots'][0]}
                    alt={`${GameScreenshotAltText[game['game_slug']]}`}
                    layout="fill"
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className={styles.games_for_team_spin}>
            <div className={styles.previous_game} onClick={previousGameCarousel}>
              <Image src="/images/home/arrow_left.png" alt="Previous" width="36" height="30" />
            </div>
            <div className={styles.arrow_space}></div>
            <div className={styles.next_game} onClick={nextGameCarousel}>
              <Image src="/images/home/arrow_right.png" alt="Next" width="36" height="30" />
            </div>
          </div>
          {isMobile && (
            <>
              <div className={styles.games_for_team_popular_games}>
                {listPopularGame.map((game, index) => (
                  <div
                    key={index}
                    className={
                      'heading_2 ' +
                      styles.games_for_team_popular_game +
                      (listPopularGame[activeGameIndex].game_slug === game.game_slug ? ' ' + styles.selected : '')
                    }
                    onClick={() => selectGame(index)}
                  >
                    {game.display_name}
                  </div>
                ))}
              </div>
              <div
                className={'button_big ' + styles.games_for_team_view_all}
                onClick={() => window.open('/blog/all-games')}
              >
                View All Games
              </div>
            </>
          )}
        </div>
      </div>

      <div className={styles.overview_section}>
        <div className={styles.create_virtual_outing_bg_vector}>
          <Image src="/images/home/create_virtual_outing_pc_bg_vector.png" alt="Bg" layout="fill" />
        </div>
        <div className={styles.overview_section_container}>
          <div className={styles.overview_section_header}>
            <div className={'heading_1 ' + styles.overview_create_outing_title}>Create a Virtual Outing</div>
            <div className={'description_large ' + styles.overview_create_outing_desc}>
              The team building activities you’ve been missing are possible through Team Games! Our games will have your
              team competing and bonding as if they were in person.
            </div>
            <div className={'button_big ' + styles.overview_cta} onClick={showModalJoinOuting}>
              Create Now
            </div>
          </div>
          <div className={styles.overview_section_body}>
            <div className={styles.overview_section_body_item}>
              <div className={styles.overview_section_body_item_left}>
                <div className={'heading_2 ' + styles.overview_section_body_item_title}>
                  1.&nbsp;&nbsp;&nbsp;&nbsp;Choose a Game
                </div>
                <div className={'description_large ' + styles.overview_section_body_item_desc}>
                  Team Games offers a wide range of fun, competitive games with different skill levels for your team
                  outing. so that everyone can join in on the action!
                </div>
              </div>
              <div className={styles.overview_section_body_item_right}>
                <Image src="/images/home/choose_a_game_2.png" alt="Choose a Game" width={580} height={302} />
              </div>
            </div>
            <div className={styles.overview_section_body_item}>
              <div className={styles.overview_section_body_item_left}>
                <div className={'heading_2 ' + styles.overview_section_body_item_title}>
                  2.&nbsp;&nbsp;&nbsp;&nbsp;Schedule the Outing
                </div>
                <div className={'description_large ' + styles.overview_section_body_item_desc}>
                  With outings lasting as little as fifteen minutes, anytime is gametime! Create team outings tailored
                  to your schedule and team preferences.
                </div>
              </div>
              <div className={styles.overview_section_body_item_right}>
                <Image src="/images/home/schedule_outing_2.png" alt="Schedule the Outing" width={580} height={231} />
              </div>
            </div>
            <div className={styles.overview_section_body_item}>
              <div className={styles.overview_section_body_item_left}>
                <div className={'heading_2 ' + styles.overview_section_body_item_title}>
                  3.&nbsp;&nbsp;&nbsp;&nbsp;Invite Your Team
                </div>
                <div className={'description_large ' + styles.overview_section_body_item_desc}>
                  Copy the invitation link, then easily forward that link to your team members, along with an optional
                  video meeting link!
                </div>
              </div>
              <div className={styles.overview_section_body_item_right}>
                <Image src="/images/home/invite_team_2.png" alt="Invite Your Team" width={580} height={216} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {!isMobile && (
        <div className={styles.get_your_team_involved}>
          <div className={styles.get_your_team_involved_container}>
            <div className={styles.get_your_team_involved_left}>
              <div className={'heading_1 ' + styles.get_your_team_involved_title}>Get Your Team Involved</div>
              <div className={'description_large ' + styles.get_your_team_involved_desc}>
                Joining a team outing is only a click away! Don’t worry if someone is running late, they can join in at
                any time. Team Games is the new way to bring your team together in an efficient, easy and fun manner.
              </div>
              <div className="button_big" onClick={showModalJoinOuting}>
                Create Now
              </div>
            </div>
            <div className={styles.get_your_team_involved_right}>
              <div className={styles.get_your_team_involved_right_row}>
                <div className={styles.get_your_team_involved_img_container}>
                  <Image src="/images/home/get_your_team_involved_1.png" alt="Get your team involved" layout="fill" />
                </div>
                <div className={styles.get_your_team_involved_img_container}>
                  <Image src="/images/home/get_your_team_involved_2.png" alt="Get your team involved" layout="fill" />
                </div>
              </div>
              <div className={styles.get_your_team_involved_right_row}>
                <div className={styles.get_your_team_involved_img_container}>
                  <Image src="/images/home/get_your_team_involved_3.png" alt="Get your team involved" layout="fill" />
                </div>
                <div className={styles.get_your_team_involved_img_container}>
                  <Image src="/images/home/get_your_team_involved_4.png" alt="Get your team involved" layout="fill" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={styles.better_with_video}>
        <div className={styles.better_with_video_container}>
          <div className={styles.better_with_video_left}>
            <div className={styles.better_with_video_left_img}>
              {isMobile ? (
                <Image src="/images/home/better_with_video_mobile.png" alt="Better with video" layout="fill" />
              ) : (
                <Image src="/images/home/better_with_video_2.png" alt="Better with video" layout="fill" />
              )}
            </div>
          </div>
          <div className={styles.better_with_video_right}>
            <div className={'heading_1 ' + styles.better_with_video_title}>Better With Video</div>
            <div className={'description_large ' + styles.better_with_video_desc}>
              Bring your experience to the next level by using your video application of choice. Set your team outing
              apart from the rest. You won’t regret it!
            </div>
            <div className={styles.better_with_video_platform_supports}>
              <a href="https://zoom.us/meeting/schedule" target="_blank" rel="noreferrer">
                <Image src="/images/home/Zoom2.png" alt="Zoom Icon with Link" width={65} height={67} />
              </a>
              <a href="https://www.skype.com/en/free-conference-call" target="_blank" rel="noreferrer">
                <Image src="/images/home/Skype2.png" alt="Skype Icon with Link" width={65} height={67} />
              </a>
              <a href="https://discord.gg/2V6qJKmHSJ" target="_blank" rel="noreferrer">
                <Image src="/images/home/Discord2.png" alt="Discord Icon with Link" width={65} height={52} />
              </a>
              <a
                href="https://www.microsoft.com/en-us/microsoft-teams/online-meetings"
                target="_blank"
                rel="noreferrer"
              >
                <Image src="/images/home/MSTeams.png" alt="Microsoft Teams Icon with Link" width={72} height={67} />
              </a>
              <a href="https://meet.google.com" target="_blank" rel="noreferrer">
                <Image src="/images/home/Meet.png" alt="Google Meet Icon with Link" width={70} height={57.6} />
              </a>
            </div>
          </div>
        </div>
      </div>

      <ModalMessage />
      <ModalJoinOuting />
      <AppFooter />
    </div>
  )
}

export default IndexPage
