export const IconArrowButton = ({ color = 'black' }) => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="6.59985" width="12.6933" height="1.86667" rx="0.933333" fill={color} stroke={color} />
      <path
        d="M8.85174 1C9.07984 1 9.29511 1.10552 9.43484 1.28581L14.078 7.27704C14.4537 7.7618 14.1082 8.46667 13.4949 8.46667C13.2668 8.46667 13.0516 8.36115 12.9118 8.18085L8.26863 2.18963C7.89294 1.70486 8.23843 1 8.85174 1Z"
        fill={color}
        stroke={color}
      />
      <path
        d="M8.85174 14.4399C9.07984 14.4399 9.29511 14.3344 9.43484 14.1541L14.078 8.1629C14.4537 7.67814 14.1082 6.97328 13.4949 6.97328C13.2668 6.97328 13.0516 7.07879 12.9118 7.25909L8.26863 13.2503C7.89294 13.7351 8.23843 14.4399 8.85174 14.4399Z"
        fill={color}
        stroke={color}
      />
    </svg>
  )
}

export default IconArrowButton
